/* Medium Devices, Desktops */
@media screen and (min-width:768px) and (max-width:1199px) {
    .aboutus-block .aboutus-img {
        height:230px;
    }
    header .collapse-menu .language-list {
        top:150px;
    }
}

@media screen and (max-width:980px) {
    .news .news-img {
        &:before {
            transform:scale(1);
            opacity:1;
        }
        .news-content { opacity:0; }
        .news-content-hover { opacity:1; }
    }
}

@media screen and (min-width:768px) and (max-width:991px) {
    .newsletter, .contactus{
        h3 {
            font-size:30px;
        }

        font-size:15px;


    }
    .banner {
        .carousel h3 {
            font-size:26px;
        }
        .banner-bottom-color {
            h3 {
                font-size:30px;
            }
            .media {
                min-height:270px;
                img {
                    max-width:150px;
                }
            }
        }
    }
    .content {
        h4 {
            font-size:25px;
        }
        .news h4:after {
            width:115px;
        }
        .carousel-indicators li,
        .carousel-indicators li.active, 
        .carousel-indicators li img {
            width:45px;
            height:45px;
        }
        .carousel-indicators {
            bottom:-65px;
        }
        .campaign-block {
            .media {
                h2 { font-size:25px; }
                h4 { font-size:18px; }
            }
        }
    }
    .newsletter .container svg {
        max-width:110px;
        top:60px;
        left:10px;
    }
    footer ul li img {
        max-width:105px;
    }
    .campaign-right {
        ul {
            li a {
                font-size:16px;
            }
        }
    }
    .downloads h3 {
        padding:0 15px;
    }
    .news.showroom .news-img .news-content-hover {
        height:90%;
    }

}

@media screen and (max-width:991px) {
        .grid-item {
    min-width: 360px;

}
    .error-404 {
        min-height:auto;
        h1 {
            font-size:24px;
            line-height:44px;
            padding:24px 15px 0;
            margin:0 -15px;
        }
        h3 {
            font-size:83px;
            line-height:57px;
            margin-bottom:0;
        }
        p { 
            font-size:25px;
            margin-bottom:0;
        }
        .btn {
            font-size:16px;
            padding:1px 13px;
        }
        img { margin:0 0 41px; }
        .error-content {
            float:none;
            margin-top:23px;
            position:relative;
            top:auto;
            right:auto;
        }
    }
}

@media screen and (min-width:768px) and (max-width:880px) {
    .news .news-img .news-content-hover {
        padding:10px 15px;
        height:85%;
    }
    .home_h1{
        padding-bottom: 40px;
        padding-top: -20px;
        h1{
            font-size: 28px;
        }
    }
}
@media screen and (max-width:767px) {
    .newsletter, .contactus{
        h3 {
            font-size:30px;
        }

        font-size:15px;
    }
    .home_h1{
        padding-bottom: 40px;
        padding-top: -20px;
        h1{
            font-size: 28px;
        }
    }
    .padding-value {
        padding-top:59px;
    }
    body {
        background:$btn-primary-color;
    }
    .navbar-open header .collapse-menu {
        padding-top:79px;
    }
    header {
        .navbar {
            min-height:90px;
            .navbar-header {
                width:auto;
                & > div {
                    margin-right:15px;
                }
                .form-group {
                    &:before {
                        font-size:11px;
                        top:5px;
                        left:5px;
                    }
                    .form-control {
                        width:18px;
                        height:21px;
                        padding:6px 10px;
                    }
                }
            }
        }
        .collapse-menu {
            .navbar-nav {
                /*height:335px;*/
                margin:0;
                li {
                    a {
                        font-size:17px;
                        line-height:18px;
                        padding:5px 0;
                        margin:2px 0;
                        letter-spacing:0;
                    }
                    &.bottom-menu {
                        padding-top:5px;
                        li {
                            padding:0 5px;
                        }
                        a {
                            font-size:14px;
                            line-height:18px;
                        }
                    }
                }
            }
            .language-list {
                position:static;
                display:block;
                text-align:center;
                li {
                    display:inline-block;
                }
            }
        }
        .navbar-header {
            .form-group {
                margin:10px 0 5px;
                .form-control {
                    &:focus,&.input-value {
                        width:140px;
                        padding:6px 20px;
                        font-size:12px;
                    }
                }
            }
            .btn {
                margin-top:0;
                span, .fa {
                    font-size:18px;
                }
            }
            .navbar-brand {
                padding:10px 15px;
                svg {
                    width:50%;
                }
            }
        }
    }
    .banner {
        padding-top:80px;
        min-height:initial;
        h1 {
            font:24px/30px ($catamaran-light);
            letter-spacing:0.5px;
            text-align:center;
            margin:0 -15px;
        }
        .home-slider {

            overflow:hidden;
            width: 100%;
            padding: 0;
            margin: 0;
        }
        .banner-carousel {
            padding:0;
            margin:0;
            min-height:320px;
            .carousel-indicators  {
                bottom:0;
            }


            .item {
                text-align:center;
                img {
                    max-width:232px;
                    display:inline-block;
                    margin-top:0 !important;
                }
                .btn {
                    margin-top:12px;
                    line-height:15px;
                    max-width:200px;
                    display:inline-block;
                }
            }

            .carousel-control .fa {
                font-size:26px;
                top:39%;
            }
        }
        .banner-bottom {
            .row {
                background-color:$btn-primary-color;
                > div {
                    padding:0;
                    &:first-child:after {
                        content:'';
                        width:1px;
                        height:92%;
                        background-color:$blue-2c;
                        position:absolute;
                        top:15px;
                        right:0;
                    }
                }
            }
            h3 {
                font:16px/18px ($catamaran-extralight);
                margin-bottom:10px;
                min-height:40px;
            }
            & > div > div:first-child > div,
                & > div > div:last-child > div {
                background:none;
            }
            & > div > div:last-child > div {
                img {
                    margin: auto;
                }
            }
            .banner-bottom-color {
                .media {


                    padding:15px;
                    img {
                        max-width:130px;
                    }
                    p {
                        margin:5px 0 10px;
                    }
                }
                .media-left,
                .media-body {
                    p{ font-size:14px;}
                    display:block;


                }
                .media-body {
                    width:100%;
                }
                .btn { font-size:14px; }
            }
        }
    }
    .content {
        padding:15px 0;
        .carousel-indicators {
            li,li.active {
                margin:0 3px 0 0;
            }
            li:last-child {
                margin:0;
            }
            li,li.active,
            li img {
                width:60px;
                height:60px;
            }
        }
        h4 {
            font-size:18px;
            margin:0 0 4px !important;
        }
        .catelog {
            padding:8px 15px 15px;
            margin-bottom:32px;
        }
        .showroom .carousel {
            margin-top:-43px;
        }
        .home-news { margin-top:105px; }
        .news {
            h4	{
                line-height:15px;
                text-align:center;
                &:after {
                    display:none;
                }
            }
        }
        .campaign-block {
            .media {
                h2, h4 {
                    font-size:18px;
                    line-height:18px;
                }
                p { margin-top:12px; }
            }
        }
    }
    .newsletter {
        .container img {
            display:none;
        }
        padding:20px 0 8px; 
        h3 {
            font-size:16px;
        }
        .container {
            img {
                max-width:105px;
                top:45px;
                left:20px;
            }
        }
        p {
            display:inline-block;
            margin:25px 0;
            max-width:350px;
        }
        form {
            display:inline-block;
            margin-top:0;
            & > .row {
                margin:0;
                & > div {
                    display:table-cell;
                    float:none;
                    padding:0;
                    .form-control, .btn {
                        font-size:14px;
                        min-height:34px;
                        height:auto;
                    }
                    .btn {
                        padding:4px 9px;
                        border-left:0;
                    }
                    &:first-child {
                        min-width:185px;
                    }
                }
            }
        }
    }
    .contactus {
        padding:8px 0 20px;
        text-align:center;
        h3 {
            font-size:18px;
        }
        ul {
            margin-top:18px;
            li,
            li p {
                font-size:15px;
            }
            li:first-child,
                li:last-child {
                margin-bottom:20px;
            }
        }
        .row > div:last-child {
            h3, a {
                display:inline-block;
                vertical-align:middle;
            }
            h3 { margin:13px 24px 0 0; }
            a {
                margin-top:12px;
                .fa {
                    font-size:27px;
                }
            }
            &:before {
                display:none;
            }
        }
    }
    footer {
        .pull-left,
        .pull-right { float:none !important; }
        ul {
            margin:27px 0;
            text-align:center;
            li {
                img {
                    max-width:84px;
                }
                &:last-child {
                    margin-top:10px;
                    width:100%;
                }
            }
        }
    }
    .campaign-block, 
    .contactus-block, 
    .downloads-block, 
    .lighting-block, 
    .sitemap-block, 
    .aboutus-block,
    .news-block {
        header {
            width:100%;
            min-height:80px;
            background-size:cover;
        }
    }
    .content-head {
        margin: auto;
        padding:10px 0 0;
        h1 {
            font-size:24px;
            line-height:30px;
            margin-bottom:7px;
            padding:0 15px;
        }
        h3 {
             font-size:24px;
        }
        a {
            margin:1px 12px 0 0;
            padding:2px 20px;
        }
        &.lighting-head {
            border:0;
            padding:11px 0 0;
            h1 {
                border-bottom:1px solid $blue;
                float:none !important;
                line-height:26px;
                padding-bottom:10px;
            }
            .no-items {
                margin:9px 15px 0 0;
                span {
                    color:$blue-2c;
                }
            }
        }
        .dropdown-toggle {
            border-color:$blue-2c;
            color:$blue-2c;
        }
        .dropdown-menu {
            & > li > a {
                color:$blue-2c;
                &:before {
                    background-color:$blue-2c;
                }
                &:hover {
                    background-color:$blue-2c;
                    color:$btn-primary-color;
                }
            }
        }
    }
    .campaign { margin:0 0 8px; }
    .campaign-tab {
        border:0;
        padding:15px 0;
        margin-top:20px;
        h2 {
            font-size:24px;
            padding-bottom:0;
            margin:0 0 15px;
            span {
                display:block;
                float:left;
                letter-spacing:-1px;
            }
            img { 
                float:right;
                margin:-4px -5px 0 0;
            }
        }
        ul {
            margin:3px 0 12px;
            text-align:center;
            &.nav {
                margin-top:30px;
                li {
                    float:none;
                    display:inline-block;
                    vertical-align:middle;
                    padding:0 2px;
                    a {
                        width:48px;
                        height:48px;
                    }
                    &:first-child a, &:last-child a {
                        padding:8px 0;
                    }
                }
            }
        }	
        .tab-content {
            text-align:center;
            .tab-pane.active {
                display:inline-block;
            }
        }
    }
    .campaign-right {
        margin-top:10px;
        .btn { margin:0 0 55px; }
        ul {
            text-align:center;
        }
    }
    .campaign-list {
        padding:42px 0 20px;
        .thumbnail {
            margin-bottom:30px;
            h3 {
                font-size:24px;
            }
            p {
                font-size:16px;
                line-height:20px;
                margin:5px 0;
            }
        }
    }
    .contactus-block {
        .contact {
            h4 {
                line-height:20px;
                margin:0;
            }
            .contact-form {
                margin-bottom:0;
                padding:0 0 30px;
                form {
                    margin-top:20px;
                }
                .btn { max-width:none; }
            }
            .contact-address {
                margin-top:25px;
                float:none !important;
                text-align:center;
                .navbar-btn {
                    margin:21px 0;
                }
            }
        }
    }
    .content-head + h3 {
        font-size:24px;
        line-height:27px;
        margin:18px 0 0;
        & + p {
            margin-top:10px;
            line-height:23px;
        }
    }
    .downloads {
        padding:2px 0 3px;
        h3 {
            font-size:24px;
            margin-bottom:32px;
        }
        img {
            width:100%;
            margin-bottom:10px;
        }
    }
    .campaign-bottom {
        padding-bottom:30px;
    }
    .lighting-block {
        .lighting-output, &.electric-material .contact {
            background:$btn-primary-color;
        }
        &.electric-material {
            .light-content {
                padding-bottom:15px;
                .pagination {
                    margin:10px 0;
                }
            }	
        }
        .light-content {
            margin-top:15px;
            padding-bottom:15px;
        }
        .sidebar {
            height:40px;
            overflow:hidden;
            padding:0;
            margin-bottom:25px;
            @include transition-effect($transition-05);
            h3 {
                font-size:18px;
                line-height:16px;
                position:relative;
                padding:12px 8px;
                cursor:pointer;
                &:after {
                    content:'\f0d7';
                    font:38px fontawesome;
                    position:absolute;
                    top:0;
                    right:10px;
                }
            }
            [role^="tablist"] {
                padding:0 12px;
            }
            &.auto-height {
                height:auto;
            }
        }
        .campaign-tab {
            margin:20px -15px 0;
            padding:10px 15px;
        }
        .thumbnail {
            padding:4px 9px;
            margin-bottom:13px;
            h3 {
                font-size:16px;
                line-height:20px;
                padding:0;
                margin:0;
            }
            .btn {
                font-size:14px;
                padding:0 8px;
                margin:8px auto 4px;
                display:block;
            }
            .led-icon {
                height:auto;
                img { max-width:82px; }
                img:nth-of-type(2) {
                    width:30px;
                }
            }
        }
        .pagination {
            margin:10px 0;
        }
        .related-products {
            padding-bottom:6px;
            h3 {
                font-size:18px;
                padding-bottom:17px;
            }
            .thumbnail {
                margin-bottom:28px;
                h3 { padding-bottom:3px; }
                .btn {
                    margin:5px auto 0;
                    padding:0px 8px;
                }
            }
        }
        .campaign-right {
            margin-top:22px;
        }
        .campaign-bottom {
            margin-top:27px;
            h3 {
                font-size:18px;
            }
        }
    }
    .pagination li a {
        margin:0 2px;
    }
    .news-block .news {
        text-align:center;
        padding:20px 0 11px;
        .news-img {
            display:inline-block;
            margin:0 0 15px;
        }
        &.news-list .pagination {
            margin:0;
        }
    }
    .news-output {
        padding:0 0 19px;
        .carousel {
            margin:25px 0 20px;
        }
        h2 {
            margin-top:15px;
            font-size:21px;
            line-height:28px;
        }
        p { margin:0 0 18px; }
        .btn { margin-top:0; }
    }
    .news.showroom {
        text-align:center;
        .news-img {
            display:inline-block;
            .news-content {
                padding:24px 15px;
            }
        }
    }
    .aboutus, .terms {
        padding:10px 0;
        .visible-xs {
            img {
                max-width:150px;
                display:inline-block;
                margin-bottom:45px;
            }
        }
        h3 {
            font-size:24px;
        }
        &.about-bottom h3 {
            margin-top:30px;
        }
    }
    .terms {
        padding:10px 0 20px;
    }
    .aboutus-block {
        .aboutus-img {
            height:123px;
            margin:12px 0 38px;
            img {
                width:100%;
                height:260px;
                margin-top:-65px;
            }
        }
    }
    .sitemap-block {
        .sitemap {
            max-width:220px;
            min-height:517px;
            left:50%;
            margin-left:-75px;
            padding:18px 0;
            position:relative;
            ul {
                margin:0;
                float:none;
                &:before, li a:before {
                    display:none;
                }
                li {
                    padding:0 0 10px !important;
                    &.active > a { color:$blue-2c; }
                    ul {
                        list-style:disc;
                        padding-left:35px;
                        color:$btn-primary-color;
                        width:220px;
                        li:last-child { padding-bottom:0 !important; }
                    }
                }
            }
        }
    }
    .news.showroom.showroom_gallery .modal { 
        .modal-dialog {
            margin:20px auto 0;
        }
        .carousel-control {
                        width:auto;
                        bottom:auto;
                        background:none;
                        top:25%;
                        font-size:30px;
                        &.left { left:10px; }
                        &.right { right:10px; }
                    }
    }
}

/* Extra Small Devices, Phones */
@media screen and (max-width:480px) {
    .banner-bottom{
        .media h3 {
            font-size: 16px;
            line-height: 20px;
        }
    }

        .grid-item {
            width: 100%;
    min-width: 320px;

}
    .banner {
        .home-slider{
            position:absolute;
            top:0;
            .item img {
                min-width:100%;
                min-height: 100%;
                max-width: none;
            }
        }
        .banner-carousel {
            padding: 0;
            margin: 0 ;
            min-height: 100%;
            min-height: 320px;.container{
                padding:0!important;
                margin:0!important;   
            }
        }
    }     
    .content {
        .campaign-block {
            .media {
                padding:15px;
                position:relative;
                margin-bottom:18px;
                h2 {
                    margin:2px 0 3px;
                }
                p { 
                    max-width:138px;
                    font-size:15px;
                    line-height:20px;
                }
                .media-right, .media-body {
                    display:block;
                    width:auto;
                }
                .media-right {
                    position:absolute;
                    top:14px;
                    right:6px;
                    img { max-width:110px; }
                }
            }
        }
    }
    .news.showroom.showroom_gallery .modal .modal-content .carousel-control {
        &.left { left:-10px; }
        &.right { right:-10px; }
    }
    .newsletter {
        .container img {
            display:block;
        }
        .form-group {
            margin-bottom:11px;
        }
        p {
            font-size:14px;
            max-width:100%;
            text-align:center;
            float:right;
            padding: 0;
            margin-bottom:20px;
        }
    }
    .direitos{
        p{
            font-size: 14px;
        }
        li img{
            margin-top: 10px;
        }
    }
}