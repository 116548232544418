/* Footer
================== */
footer {

    background-color:$blue;
    padding:10px 0;
    .container {
        width:auto;
        max-width:1170px;
    }
    p {
        margin-top:22px;
        color:$btn-primary-color;
        letter-spacing:0;
    }
    ul { margin:10px 0 0; }
    li .logo-w{
        margin-bottom: 20px;
    }
}
