@mixin btn-blue() {
	min-width:168px;
	margin-left:-84px;
	position:absolute;
	bottom:34px;
	left:50%;
}

@mixin btn-white() {
	background:none;
	border:1px solid $btn-primary-color;
	border-radius:0;
	font:16px ($catamaran-light);
	color:$btn-primary-color;
	line-height:20px;
	&:hover, &:focus {
		color:$blue;
	}
	&:active { color:$btn-primary-color; }
	&:before {
		background-color:$btn-primary-color;
	}
	@include animate-btn;
}

@mixin blue-block-color() {
	background-color:$btn-primary-color;
	border:1px solid $blue;
	padding:15px;
}

@mixin transition-effect($transition-05) {
	-webkit-transition:$transition-05;
	-moz-transition:$transition-05;
	-o-transition:$transition-05;
	-ms-transition:$transition-05;
	transition:$transition-05;
}

@mixin header() {
	width:100%;
	min-height:160px;
}

@mixin flex-img() {
	display:flex;
	align-items:center;
    justify-content:center;
	flex-direction:column;
}

@mixin menu-hover() {
	content:'';
	position:absolute;
	bottom:0;
	left:50%;
	background:$blue;
	width:0;
	height:3px;
	transform:translateX(-50%);
	@include transition-effect($transition-05);
}

@mixin animate-btn() {
	transform:translateZ(0px);
	transition-duration:0.6s;
	&:before {
		content:"";
		outline:0;
		position:absolute;
		top:0;
		bottom:0;
		left:0;
		right:0;
		transform:scaleX(0);
		transition-duration:0.6s;
		z-index:-1;
	}
	&:hover::before {
		transform:scaleX(1);
		transition-timing-function:cubic-bezier(0.52, 1.64, 0.37, 0.66);
	}
}

@mixin mexe-img() {
img{ transform: scale(1); transition: all .2s ease-in-out;}
 &:hover img { transform: scale(0.8); transition: all .2s ease-in-out;}
}

@mixin trans-img() {
img{ opacity:1; transition: all .1s ease-in-out;}
 &:hover img { opacity:1; transition: all .2s ease-in-out;}
}
