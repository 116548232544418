@import "../css/bootstrap.min.css";
@import "../css/plugins/owl-carousel/owl.carousel.css";
@import "../css/plugins/owl-carousel/owl.transitions.css";
@import "../css/plugins/magnific-popup.css";
@import "../css/animate.min.css";

//base
@import "base/fonts";
@import "base/variaveis";

//helpers
@import "helpers/mixins";

//components
//@import "components/buttons";
//@import "components/navigation";
//@import "components/plugins";
@import "components/ui-animation";

//layout
//@import "layout/pages";
@import "layout/header";
//@import "layout/sidebar";
@import "layout/footer";

//pages
//@import "pages/homepage";
//@import "pages/quemsomos";
//@import "pages/galeria";
//@import "pages/contactos";

//custom
@import "pages/custom";

//mediaqueries
@import "mediaqueries/mediaqueries";