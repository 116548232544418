// Custom Variables

$image-path :"../../images/";

$catamaran:  'Catamaran', sans-serif;
$catamaran-bold       : 700;
$catamaran-extrabold  : 800;
$catamaran-extralight : 200;
$catamaran-heavy       :900;
$catamaran-light      :300;
$catamaran-medium      : 500;
$catamaran-regular     : 400;
$catamaran-semibold   : 600;
$catamaran-thin        :100;

//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base  : #000;
$brown      : #4c2b18;
$blue       : #00adef;
$blue-2c    : #2c518c;
$blue-00    : #0094cc;
$orange-ed  : #edb886;
$grey-80    : #808080;
$grey-f7    : #f7f7f7;
$grey-b3    : #b3b3b3;
$purple     : #3d2a46 ;

$transition-05 : 0.5s linear;
$transition-2 : 2s linear;
$transition-01 : 0.1s linear;

//== Pagination
//
//##

$pagination-border:   #ddd;

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-primary-color:  #fff;