html, body {
    height:100%;
    .main-block {
        min-height:100%;
        section:nth-of-type(1) {
            overflow:hidden;
        }
    }
}

.container {
    max-width:1000px;
    width:auto;
}

.m-0 {
    margin:0;
}

.mb-30 {
    margin-bottom:30px;
}

.padding-value {
    padding-top:150px;
}

a, .btn {
    @include transition-effect($transition-05);
    display:block;
    &:hover, &:focus, &:active {
        text-decoration:none;
        outline:none !important;
        box-shadow:none;
    }
}

h1 {
    font:48px ($catamaran);
    font-weight: $catamaran-regular;
    color:$blue;
    letter-spacing:-2px;
    margin:0;
}

h2 {
    font:48px/35px ($catamaran);
    font-weight: $catamaran-regular;
    color:$blue-2c;
}

h3 {
    font:36px ($catamaran);
    font-weight: $catamaran-regular;
    color:$blue-2c;
    letter-spacing:-0.75px;
    margin:0;
}

h4 {
    font:30px ($catamaran);
    font-weight: $catamaran-extralight;
}

p,address {
    font:16px/22px ($catamaran);
    font-weight: $catamaran-regular;

    letter-spacing:0.5px;
    color:$blue-2c;
}

.btn-blue {
    background-color:$blue;
    font:16px ($catamaran);
    font-weight: $catamaran-light;
    color:$btn-primary-color;
    padding:4px 12px;
    border:0;
    border-radius:0;
    display:inline-block;
    &:hover,&:focus {
        color:$btn-primary-color;
    }
    &:before {
        background-color:$blue-00;
        color:$btn-primary-color;
    }
    @include animate-btn;
}

.form-control:focus {
    box-shadow:none;
}

body {
    background:url(../images/body-bg.jpg) no-repeat;
    letter-spacing:0.5px;
    overflow-x:hidden;
}
.home_h1{
    h1{
      font-size: 22px!important;  
    }  
    padding-bottom: 40px;
    padding-top: -20px;
}
.border-bottom {
    border-bottom:1px solid $blue;
}

@keyframes fadeInRight {
    0% {
        left:5%;
        opacity:0;
    }
    100% {
        left:0;
        opacity:1;
    }
}

/* Index
==================== */
.banner {
    padding-top:150px;
    min-height:998px;
    position:relative;
    overflow:hidden;
    & > .container{
        position: relative;
    }
    h2 {
        border-bottom:1px solid $btn-primary-color;
        line-height:43px;
        color:$btn-primary-color;
        width: 105%;
    }
    .marcadores{
        position: relative;
        z-index:11;
        width: 100%;
        .carousel-indicators {
            position:absolute;
            bottom:50px;
            li, li.active {
                width:10px;
                height:10px;
                margin:0 5px;
                border-radius:0;
                position:relative;
            }
            li.active:before {
                content:'';
                border:1px solid $btn-primary-color;
                width:16px;
                height:16px;
                top:-4px;
                left:-4px;
                position:absolute;
            }
        }
    }
    .setas-banner{
        position: absolute;
        width: calc(100% - 30px);
        height: 50px;
        top:50%;
        left: 50%;
        transform: translate(-50% , -50%);
        background:none;
        opacity:1;
        z-index:10;

        .carousel-control {
            background: none;
            .fa {
                position:absolute;
                left:10px;
                top:0%;
                font-size:50px;
                text-shadow:none;
                &.fa-angle-right {
                    left: initial;
                    position:absolute;
                    right:10px;
                    top: 0px;
                }
            }
        }
    }
    .home-slider {
        position:absolute;
        top:0;
        .item img {
            min-width:100%;
            min-height: 100%;
            max-width: none;
        }
    }
    .banner-carousel {
        background-color:rgba(0,173,239,0.9);
        padding:37px 65px 20px;
        min-height:430px;
        margin-bottom:41px;
        margin-top:13px;
        & > div > div > div:first-child img {
            margin-top:40px;
        } 
        p {
            color:$btn-primary-color;
            line-height:20px;
        }
        .btn {
            @include btn-white;
            margin-top:27px;
        }
        h3 { 
            margin:40px 0 0;
            color:$btn-primary-color;
        }
    }
    .banner-bottom {
        & > div > div {
            @include mexe-img;
            &:first-child > div { background:rgba(255,255,255,0.9) url(../images/light-bg.png); }
            &:last-child > div  { 
                background:rgba(255,255,255,0.9) url(../images/wire-bg.png);                
            }
        }
    }
    .banner-bottom-color {
        background:rgba(255,255,255,0.9);
        h3 {
            margin-top:8px;
            letter-spacing:-1px;
        }
        .media {
            padding:15px 23px;
            margin:0;
            min-height:303px;
            p {
                margin:25px 0 18px;
            }
        }
        .btn {
            padding:2px 12px;
        }
    }
    .down-arrow {
        width:100%;
        position:absolute;
        left:0;
        bottom:0;
        a {
            border-bottom:7px solid $btn-primary-color;
            display:inline-block;
            .fa {
                width:100px;
                font-size:60px;
                color:$btn-primary-color;
                display:inline-block;
                animation:top-to-bottom 0.6s linear infinite alternate;
                transform:translateY(-6px);
            }
        }
    }
}

@keyframes top-to-bottom {
    50% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(-6px);
    }
}

/* Home Content
=================== */
.content {
    background:url(../images/home-inner-bg.png) no-repeat center top;
    padding:50px 0 50px;
    h4 {
        color:$blue-2c;
        line-height:23px;
    }
    .catelog, .showroom, .news {
        display:inline-block;
        position:relative;
    }
    .catelog {
        @include blue-block-color;
        h4 {
            margin-bottom:20px;
        }
        .btn {
            @include btn-blue;
            padding:2px 12px;
        }
    }
    .showroom {
        h4 {
            color:$btn-primary-color;
            margin:33px 0 0;
            position:relative;
            z-index:9;
        }
        .carousel {
            margin-top:-56px;
        }
        .btn {
            @include btn-blue;
            bottom:24px;
        }
    }
    .carousel-indicators {
        width:100%;
        margin:0;
        bottom:-85px;
        left:0;
        li,li.active { opacity:1;
                       margin:0 4px 0 0;
                       border:0;
                       border-radius:0;
                       position:relative;

        }
        li,li.active,
        li img {
            width:68px;
            height:68px;
        }
        li img {
            display:block;
        }
        li:last-child {
            margin-right:0;
        }
        li:hover:before {
            content:'';
            position:absolute;
            background:$blue url(../images/search.png) no-repeat center center;
            border:4px solid rgba(0,0,0,0.7);
            top:0;
            bottom:0;
            left:0;
            right:0;
        }
    }
    .campaign-block {
        margin-top:17px;
        .media {    @include mexe-img;
                    @include blue-block-color;
                    padding:18px 27px;
                    h2 { margin:9px 0 10px; }
                    h4 {
                        font:24px/14px ($catamaran);
                        font-weight: $catamaran-regular;

                        color:$blue-2c;
                    }
                    p { margin-bottom:25px; }
                    .btn {
                        padding:2px 12px;
                    }
        }
    }
}

/* Newsletter 
================== */
.newsletter {
    background-color:$btn-primary-color;
    border-top:1px solid $blue-2c;
    border-bottom:1px solid $blue-2c;
    padding:36px 0 54px;
    overflow:hidden;
    form { margin-top:10px; }
    .form-control {
        font-size:16px;
        height:45px;
        border-radius:0;
        border-color:$blue-2c;
        padding:4px 12px 7px;
        -webkit-appearance:initial;
    }
    .btn {
        min-height:45px;
        font:18px ($catamaran);
        font-weight: $catamaran-light;
        color:$blue-2c;
        background:none;
        border-color:$blue-2c;
        border-radius:0;
        &:before {
            background-color:$blue-2c;
        }
        &:hover {
            color:$btn-primary-color;
        }
        @include animate-btn;
    }
    .container {
        position:relative;
        svg {
            position:absolute;
            top:18px;
            left:-5px;
        }
    }
}

/* Contact Us
================== */
.contactus {
    background-color:$btn-primary-color;
    padding:60px 0;
    overflow:hidden;
    h3 { line-height:30px; }
    ul {
        margin-top:25px;	
        li {
            font:16px/23px ($catamaran);
            font-weight: $catamaran-regular;
            letter-spacing:0.5px;
            color:$blue-2c;
            &:first-child {
                margin-bottom:25px;
            }
            p {
                margin:0;
            }
        }
    }
    .row {
        > div:last-child {
            h3 {
                margin:57px 0 42px;
            }
            .fa {
                font-size:60px;
                color:$blue-2c;
            }
            &:before {
                content:'';
                background:url(../images/follow-us.png) no-repeat;
                width:107px;
                height:243px;
                position:absolute;
                top:0;
                right:0;
            }
        }
    }
}

/* News
================== */
.news-block {
    header {
        @include header;
        background:$blue url(../images/news-bg.jpg) no-repeat center top;
    }
    .news {
        padding:40px 0 11px;
        .news-img {
            margin:0 0 35px;
        }
        .pagination {
            margin:4px 0 20px;
        }
    }
}
.news {
    h4 {
        margin:0;
        line-height:39px;
        position:relative;
        text-align:left;
        &:after {
            content:'';
            width:170px;
            height:2px;
            background-color:$blue-2c;
            position:absolute;
            bottom:10px;
            right:0;
        }
    }
    .news-img {
        position:relative;
        margin:9px 0 15px;
        cursor:pointer;
        font:10px ($catamaran);
        font-weight: $catamaran-regular;
        overflow:hidden;
        span {
            letter-spacing:0.75px;
        }
        p {
            margin:0;
            font-size:15px;
            line-height:18px;
            color:$btn-primary-color;
        }
        .btn {
            @include btn-white;
            margin-top:15px;
        }
        .news-content-hover {
            color:$btn-primary-color;
            position:absolute;
            top:0;
            bottom:0;
            left:0;
            right:0;
            @include transition-effect($transition-05);
            @include flex-img;
        }
        .news-content {
            padding:5px;
            height:auto;
            position:absolute;
            left:10px;
            right:10px;
            top:75px;
            background-color:rgba(0,173,239,0.9);
            color:$btn-primary-color;
            @include transition-effect($transition-05);
        }
        .news-content-hover { 
            padding:45px 30px 30px;
            opacity:0;
            transition-delay:0.01s;
        }
        &:before {
            content:'';
            background-color:rgba(0,173,239,0.9);
            bottom: 10px;
            left: 10px;
            opacity: 0;
            position: absolute;
            right: 10px;
            top: 10px;
            transform: scale(0.1);
            @include transition-effect($transition-05);
        }
        &:hover {
            &:before {
                opacity:1;
                transform:scale(1);
            }
            .news-content {
                opacity:0; 
                @include transition-effect($transition-01)
            }
            .news-content-hover { 
                opacity:1;
                transition-delay:0.3s;
            }
        }
    }
    &.showroom {
        padding:37px 0 15px;
        .news-img {
            margin:9px 0 31px;
            .news-content {
                top:44.5%;
                padding:10px;
            }
        }
        .news-content-hover {
            padding:15px 15px 45px;
            @include flex-img;
            flex-direction:column;
            justify-content:flex-end;
            .news-content-color {
                p {
                    font-size:14px;
                    color:$blue-2c;
                    margin:6px 0 0;
                }
            }
            .btn {
                max-width:188px;
                display:inline-block;
                line-height:15px;
                margin-top:22px;
                font-size:13px;
                padding:6px 12px;
            }
        }
       &.showroom_gallery {
            padding:10px 0 74px;
            .news-img .news-content {
                padding:16px 10px;
                top:42%;
            }
            & > .row  {
                margin:0 -3px;
                > [class^="col-"] {
                    padding:0;
                }
            }
            .news-img {
                margin:0;
                max-width:100%;
                max-height:100%;
                padding: 2px;
            }
            .news-content-hover {
                padding:10px;
                justify-content:center;
            }
            .modal {
                p{
                    font-size: 16px;
                    color: #FFF;
                    text-align: cetner;
                }
                .modal-dialog {
                    width:100%;
                    margin:120px auto 0;
                }
                .modal-content {
                    background:none;
                    box-shadow:none;
                    border:0;
                    .carousel-inner {
                        min-height:620px;
                        img {
                            margin:auto;
                        }
                        .flex-item {
                            min-height:620px;
                            @include flex-img;
                        }
                        .item {
                            transition-property: opacity;
                        }
                        .item,
                        .active.left,
                        .active.right {
                            opacity: 0;
                        }
                        .active,
                        .next.left,
                        .prev.right {
                            opacity: 1;
                            transform: translate3d(0, 0, 0);
                        }
                    }
                    .carousel-control {
                        width:auto;
                        bottom:auto;
                        background:none;
                        top:35%;
                        font-size:50px;
                        &.left { left:20px; }
                        &.right { right:20px; }
                    }
                }
            }
        }
    }
}
.col-fake{
           padding-right: 0;
           padding-left: 0;
        }
.modal-backdrop.in { opacity:0.96; }
.news-output {
    padding:18px 0 45px;
    .carousel {
        margin:22px 0 0 10px;
        .carousel-indicators li {
            width:12px !important;
            height:12px !important;
            margin:1px 3px !important;
            background-color:$btn-primary-color;
            &.active {
                background-color:$blue;
                border-color:$blue;
            }
        }
    }
    h2 {
        color:$blue;
        font:36px/40px ($catamaran);
        font-weight: $catamaran-regular;
        margin-bottom:4px;
        letter-spacing:0;
        span {
            font-size:12px;
            line-height:18px;
            display:block;
        }
    }
    p {
        line-height:23.5px;
        margin:0 0 25px;
    }
    .btn {
        border-color:$blue;
        border-radius:0;
        font:16px/15px ($catamaran);
        font-weight: $catamaran-regular;
        padding:3px 12px;
        color:$blue;
        margin-top:8px;
    }
}

/* Error - 404
================== */
.bg-black {
    .error-404, .newsletter, .contactus {
        background-color:$gray-base;
    }
    .newsletter .btn {
        ackground-color:$btn-primary-color;
    }
}
.error-404 {
    background-color:$gray-base;
    min-height:975px;
    .container {
        position:relative;
    }
    h1 {
        letter-spacing:0.5px;
        font-family:$catamaran;
        font-weight: $catamaran-light;
        line-height:62px;
        padding-top:24px;
    }
    h3 {
        font:160px/115px ($catamaran);
        font-weight: $catamaran-regular;
        color:$blue;
        margin-bottom:7px;
    }
    p {
        font:48px ($catamaran);
        font-weight: $catamaran-regular;
        color:$blue;
        margin-bottom:20px;
    }
    .btn {
        font:18px ($catamaran);
        font-weight: $catamaran-light;
        color:$blue;
        border:2px solid $blue;
        padding:6px 40px;
        border-radius:0;
        display:inline-block;
        &:hover, &:focus, &:active {
            background-color:$blue;
            color:$btn-primary-color;
        }
    }
    img {
        margin-top:84px;
    }
    .error-content {
        float:right;
        position:absolute;
        top:287px;
        right:55px;
        z-index:9;
        &:after {
            content:'';
            background:-webkit-linear-gradient(right , rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.15));
            background:-moz-linear-gradient(right , rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.15));
            background:-o-linear-gradient(right , rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.15));
            background:-ms-linear-gradient(right , rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.15));
            background:linear-gradient(to left , rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.15));
            position:absolute;
            width:100%;
            height:100%;
            top:0;
            right:0;
        }
        &:hover:after, &:focus:after, &:active:after {
            width:auto;
        }
    }
}

/* Campaign
================== */
.campaign-block {
    header {
        @include header;
        background:$blue url(../images/campaign-bg.jpg) no-repeat center top;
    }
}

.campaign {
    margin-top:80px;
}

.content-head {
    padding:33px 0 0;
    border-bottom:1px solid $blue;
    h1 {
        line-height:45px;
        margin-bottom:10px;
        font-family:$catamaran;
        font-weight: $catamaran-regular;
        letter-spacing:0.5px;
    }
    a {
        margin-top:13px;
        padding:2px 14px;
        border:1px solid $blue;
        font:16px/16px ($catamaran);
        font-weight: $catamaran-regular;
        color:$blue;
        .fa { margin-right:5px; }
        &:before {
            background-color:$blue;
        }
        &:hover {
            color:$btn-primary-color;
        }
        @include animate-btn;
    }
    & + h3 {
        font-size:28px;
        margin:7px 0 3px;
        letter-spacing:0.2px;
    }
    span {
        font:16px ($catamaran);
        font-weight: $catamaran-regular;
        color:$blue;
        margin-right:15px;
        letter-spacing:0;
    }
    .dropdown-toggle {
        background-color:$btn-primary-color;
        border:1px solid $blue;
        border-radius:0;
        color:$blue;
        font: 16px/15px ($catamaran);
        font-weight: $catamaran-regular;
        padding:2px 12px;
    }
    .dropdown-menu {
        min-width:95px;
        & > li > a {
            border:0;
            color:$blue;
            margin:0;
            padding:3px 17px;
            line-height:20px;
            &:hover, &:focus, &:active {
                background-color:$blue;
                color:$btn-primary-color;
            }
        }
    }
    &.lighting-head {
        padding:26px 0 0;
        h1 {
            margin-bottom:0;
        }
        .no-items {
            margin:14px 0 5px;
        }
    }
}

.campaign-tab {
    background-color:$btn-primary-color;
    border:1px solid $blue;
    padding:15px 20px;
    margin-bottom:3px;
    .tab-pane a {
        max-width:285px;
        height:255px;
        @include flex-img;
    }
    h2 {
        font-size:36px;
        color:$blue;
        border-bottom:1px solid $blue;
        padding-bottom:10px;
        margin-bottom:8px;
    }
    ul {
        li {
            font:16px/20px ($catamaran);
            font-weight: $catamaran-light;
            color:$blue-2c;
            margin:0;
            a {
                background:none !important;
                border:1px solid $blue;
                dispaly:block;
                width:52px;
                height:52px;
                border-radius:0;
                padding:5px;
                transition:none;
                @include flex-img;
                img {
                    max-width:100%;
                }
            }
            &.active a {
                border:3px solid $blue;
            }
            &:first-child, &:last-child {
                padding:0 !important;
            }
            &:first-child a, &:last-child a {
                border:0;
                width:auto !important;
                height:auto !important;	
                font-size:32px;
                padding:8px 3px;
                color:$blue;
            }
        }
        &.nav {
            margin-top:70px;
            margin-bottom:18px;
        }
    }
    .btn {
        font-size:18px;
    }
}

.campaign-right {
    .btn {
        padding:8px 12px;
        margin:0 0 108px;
    }
    ul {
        li a {
            font:18px/21px ($catamaran);
            font-weight: $catamaran-light;
            color:$blue-2c;
            margin-bottom:5px;
            padding:4px 12px;
            border:1px solid $blue-2c;
            display:block;
            &:before {
                background-color:$blue-2c;
            }
            &:hover {
                color:$btn-primary-color;
            }
            @include animate-btn;
        }
    }
}

.campaign-bottom {
    padding-bottom:66px;
    margin-top:11px;
    h3 {
        font-size:24px;
        color:$blue;
        letter-spacing:0.5px;
    }
    p { line-height:24px; }
}

.campaign-list {
    padding:35px 0 60px;
    .thumbnail {
        @include mexe-img;
        border-color:$blue;
        padding:12px 12px 0;
        margin-bottom:25px;
        border-radius:0;
        h3 {
            font-family:$catamaran;
            font-weight: $catamaran-light;
            color:$blue-2c;
            margin:0;
        }
        p {
            font-size:13px;
            margin:19px 0 15px;
            &:last-child { margin:0 -12px; }
            .btn {
                font-size:13px;
                line-height:25px;
                padding:0 12px;
            }
        }
    }
}

/* Contactus
================== */
.contactus-block {
    header {
        @include header;
        background:$blue url(../images/contact-bg.jpg) no-repeat center top;
    }
    .contact {
        h4 {
            font:24px ($catamaran);
            font-weight: $catamaran-light;
            color:$blue-2c;
            margin-top:0;
        }
        form {
            .form-group { margin-bottom:8px; }
            label {
                font:16px ($catamaran);
                font-weight: $catamaran-regular;
                color:$blue-2c;
                margin:4px 4px 0;
            }
            .form-control {
                border:2px solid rgba(55,44,81,0.3);
                border-radius:0;
                font:16px ($catamaran);
                font-weight: $catamaran-regular;
                color:$grey-80;
                background:none;
                resize:none;
                height:45px;
            }
            textarea.form-control {
                height:170px;
            }
            .btn {
                max-width:170px;
                width:100%;
                height:45px;
                background-color:$btn-primary-color;
                border:2px solid rgba(55,44,81,0.3);
                border-radius:0;
                font:18px ($catamaran);
                font-weight: $catamaran-light;

                color:$blue-2c;
                margin-top:16px;
                &:before {
                    background-color:$blue-2c;
                }
                &:hover {
                    color:$btn-primary-color;
                }
                @include animate-btn;
            }
        }
        .contact-address {
            h3 {
                font-size:24px;
                line-height:36px;
            }
        }
        .contact-form {
            padding:17px 0 48px;
        }
    }
    .contact-map2 {
        background-color:$grey-f7;
        height:315px;
        & > div {
            width:100%;
            height:315px;
            > div {
                position:static !important;
                .gm-err-container {
                    height:315px;
                }
            }
        }
    }
}

.contactus {
    .contact-map > div {
        width:100%;
        height:240px;
    }
}

/* Downloads
================== */
.downloads-block {
    header {
        @include header;
        background:$blue url(../images/downloads-bg.jpg) no-repeat center top;
    }
}

.downloads {
    .show{
        @include mexe-img;
    }
    padding:43px 0 60px;
    h3 {
        font:28px/31px ($catamaran);
        font-weight: $catamaran-light;
        letter-spacing:0.5px;
        padding:0 50px;
    }
    .thumbnail {

        border-color:$blue;
        border-radius:0;
        padding:0;
    }
    img {
        margin-bottom:25px;
    }
}

/* Light
================== */
.lighting-block {
    header {
        @include header;
        background:$blue url(../images/lighting-bg.jpg) no-repeat center top;
    }
    .contact, .lighting-output {
        background:url(../images/lighting-block.png) no-repeat -290px 50px;
    }
    .light-content {
        margin-top:38px;
        padding-bottom:22px;
    }
    .sidebar {
        background-color:$btn-primary-color;
        border:1px solid $blue;
        padding:15px;
        h3 {
            font-size:24px;
            line-height:29px;
            color:$blue;
            margin-bottom:14px;
        }
        [role^="tablist"] {
            margin-bottom:5px;
            border-bottom:1px solid $grey-b3;
            &:last-child { border:0; }
            ul {
                padding:0 0 0 11px;
                margin-bottom:3px;
                li a {
                    margin-top:9px;
                    padding:0 10px;
                }
            }
            a {
                font:16px ($catamaran);
                font-weight: $catamaran-regular;
                color:$blue;
                position:relative;
                padding:0 15px;
                display:block;
                &:hover, &:focus, &:active { color:$blue-2c; }
                &:before {
                    content:'\f0da';
                    font-family:fontawesome;
                    position:absolute;
                    top:4px;
                    left:0;
                }
                &[aria-expanded^="true"] {
                    color:$blue-2c;
                    &:before {
                        content:'\f0d7';
                        color:$blue;
                        top:6px;
                        left:1px;
                    }
                }
            }
        }
    }
    .thumbnail {
        @include mexe-img;
        border-color:$blue;
        border-radius:0;
        margin-bottom:35px;
        padding:0 4px;
        h3 {
            font:18px ($catamaran);
            font-weight: $catamaran-light;
            color:$blue;
            letter-spacing:0;
            margin-bottom:8px;
        }
        .btn {
            margin:20px auto 12px;
            padding:1px 12px;
        }
        .led-icon {
            position:relative;
            display:block;
            margin-left:auto;
            margin-right:auto;
            max-width:135px;
            height:120px;
            img:nth-of-type(2) {
                position:absolute;
                top:11px;
                right:7px;
            }
            img { 
                display:block;
                max-width:100%;
                margin:0 auto;
            }
        }
    }
    .lighting-output {
        .campaign-tab {
            background-color:$blue;
            h2, ul li, ul li:first-child a, ul li:last-child a {
                color:$btn-primary-color;
            }
            h2, ul li a, ul li a.active {
                border-color:$btn-primary-color;
            }
        }
    }
    .campaign-bottom { padding-bottom:24px; }
    .related-products {
        padding-bottom:20px;
        h3 {
            padding-bottom:7px;
            color:$blue;
        }
        .thumbnail .btn {
            margin:11px 0 12px;
        }
    }
}

/* Elactrical Material
================== */
.lighting-block.electric-material {
    header {
        background:$blue url(../images/electric-material.jpg) no-repeat center top;
    }
    .light-content {
        margin-top:30px;
        padding-bottom:19px;
        .thumbnail {
            margin-bottom:25px;
        }
        .pagination {
            margin:32px 0 0;
        }
    }
    .contact {
        background:url(../images/eletrico.png) no-repeat -222px 70px;
        padding-bottom:22px;
    }
}

/* Pagination
================== */
.pagination {
    li {
        a {
            width:21px;
            height:21px;
            padding:0;
            margin:0 3px;
            font:16px/19px ($catamaran);
            font-weight: $catamaran-regular;
            color:$blue;
            border:1px solid $blue;
        }
        &:first-child a, &:last-child a {
            font:21px/21px fontawesome;
            border:0;
            border-radius:0;
        }
        &.active a,
        a:hover, a:focus, a:active {
            background-color:$blue;
            border-color:$blue;
            color:$btn-primary-color;
        }
    }
}

/* Sitemap
================== */
.sitemap-block {
    background:$blue url(../images/sitemap-bg.jpg) no-repeat center top;
    header {
        @include header;
    }
    .content-head {
        border-color:$btn-primary-color;
        h1 {
            color:$btn-primary-color;
        }
    }
    .sitemap {
        margin:-0 -10px;
        padding:47px 0;
        min-height:550px;
        ul {
            width:100%;
            max-width:220px;
            padding:0 10px;
            float:left;
            position:relative;
            &:before {
                content:'';
                background-color:$btn-primary-color;
                width:1px;
                height:100%;
                position:absolute;
                top:0;
            }
            li {
                padding:0 11px 13px;
                a {
                    display:block;
                    font:16px/19px ($catamaran);
                    font-weight: $catamaran-extralight;
                    color:$btn-primary-color;
                    letter-spaciing:0;
                    padding:0 0 9px;
                    position:relative;
                }
                &.border a {
                    &:before {
                        content:'';
                        background-color:$btn-primary-color;
                        width:100%;
                        height:1px;
                        position:absolute;
                        bottom:0;
                        left:-11px;
                    }
                }
                &:last-child {
                    padding:0 11px;
                }
            }
        }
    }
}

/* AboutUs
================== */
.aboutus-block {
    header {
        background:$purple url(../images/aboutus-bg.jpg) no-repeat center top;
        @include header;
    }
    .aboutus-img {
        height:384px;
        margin:71px 0 50px;
        overflow:hidden;
        transform:skewY(-6deg);
        img {
            margin-top:-135px;
        }
    }
}
.aboutus, .terms {
    padding:30px 0;
    h3 {
        font-size:28px;
        margin-bottom:11px;
        letter-spacing:0;
    }
    p {
        line-height:24px;
        margin-bottom:25px;
    }
}

.terms {
    .terms-content {
        h3 { margin-bottom:16px; }
        p:last-child {
            margin-bottom:10px;
        }
    }
}

/* footer
================== */

.direitos{
    p{
        font-size: 16px;
    }
    li img{
        margin-top: auto;
    }
}