/* Header
==================== */
.relative {
	header {
		.navbar {
			background-color:rgba(255,255,255,0.95);
			min-height:50px;
			.navbar-brand svg {
                            @include transition-effect($transition-05);
                          
				max-width:60%;
			}
			.navbar-header {
				.btn {
					margin-top:8px;
					p {
						text-shadow:none;
						color:$blue;
					}
				}
				.form-group {
					margin:8px 0 0;
				}
			}
			.collapse-menu {
				display:none;
			}
		}
	}
}
.animate {
	header {
		.navbar {
			position:fixed;
			top:0;
			background-color:rgba(255,255,255,0.95);
			border-bottom:1px solid $pagination-border;
			min-height:50px;
			@include transition-effect($transition-05);
			.navbar-header {
				.form-group {
					margin:8px 0 0;
				}
				.btn {
					margin-top:8px;
					p {
						text-shadow:none;
						margin-bottom:0;
					}
				}
			}
			.navbar-brand svg {
				max-width:60%;
                                @include transition-effect($transition-05);
			}
		}
		.collapse-menu .navbar-nav::before {
			top:150px;
		}
	}
}

.navbar-open.animate,
.animate,
.navbar-open {
	header .navbar-header .btn p {
		color:$blue;
	}
}

.navbar-open {
	header {
		.navbar {
			position:fixed;
		}
		.navbar-header .btn p {
			margin:0;
			text-shadow:none;
			&.show-btn { display:block; }
			&.hide-btn { display:none; }
		}
		.navbar-nav li {
			animation:fadeInRight .8s linear forwards;
			&:nth-of-type(1) {
				animation-delay:0.1s;
			}
			&:nth-of-type(2) {
				animation-delay:0.2s;
			}
			&:nth-of-type(3) {
				animation-delay:0.3s;
			}
			&:nth-of-type(4) {
				animation-delay:0.4s;
			}
			&:nth-of-type(5) {
				animation-delay:0.5s;
			}
			&:nth-of-type(6) {
				animation-delay:0.6s;
			}
			&:nth-of-type(7) {
				animation-delay:0.7s;
			}
			&:nth-of-type(8) {
				animation-delay:0.8s;
			}
			&:nth-of-type(9) {
				animation-delay:0.9s;
			}
			&:nth-of-type(10) {
				animation-delay:1.0s;
			}
			&:nth-of-type(11) {
				animation-delay:1.1s;
			}
			&:nth-of-type(12) {
				animation-delay:1.2s;
			}
			&:nth-of-type(13) {
				animation-delay:1.3s;
			}
		}
	}
	&.animate {
		header {
			.navbar {
				background:none;
				border-bottom:none;
			}
		}
	}
	header {
		.collapse-menu {
			top:0;
			opacity:0.98;
			padding-top:133px;
		}
	}
}

header {
	.navbar {
		position:absolute;
		top:0;
		left:0;
		right:0;
		z-index:99;
		width:100%;
		margin:0;
		border-radius:0;
		min-height:150px;
	}
	.navbar-header {
		width:100%;
		float:none;
		.btn {
			background:none;
			padding:0;
			margin-top:20px;
			p {
				color:$btn-primary-color;
				text-shadow:1.5px 0 0 $gray-base;
				&.show-btn {
					display:none;
				}
			}
			span {
				font:20px ($catamaran);
                                font-weight: $catamaran-medium ;
				margin-right:8px;
			}
			.fa {
				font-size:20px;
			}
		}
		.navbar-brand {
			height:auto;
		}
		.form-group {
			position:relative;
			margin-top:16px;
			&:before {
				content:'\f002';
				font:18px fontawesome;
				position:absolute;
				top:6px;
				left:8px;
				color:$btn-primary-color;
				pointer-events:none;
			}
			.form-control {
				background:$blue;
				border:0;
				border-radius:0;
				width:32px;
				height:32px;
				color:$btn-primary-color;
				@include transition-effect($transition-05);
				&:focus,&.input-value {
					width:250px;
					padding:6px 30px 9px;
				}
			}
		}
	}
	.collapse-menu {
		width:100%;
		height:100%;
		position:fixed;
		top:-200%;
		left:0;
		@include transition-effect($transition-05);
		background-color:rgba(255,255,255,0.98);
		-webkit-transition:opacity 0.35s ease 0s, top 0.5s ease 0s;
		-moz-transition:opacity 0.35s ease 0s, top 0.5s ease 0s;
		-o-transition:opacity 0.35s ease 0s, top 0.5s ease 0s;
		-ms-transition:opacity 0.35s ease 0s, top 0.5s ease 0s;
		transition:opacity 0.35s ease 0s, top 0.5s ease 0s;
		z-index:-1;
		.language-list {
			position:fixed;
			top:20px;
			right:0;
			li {
				a {
					background-color:$btn-primary-color;
					border:1px solid $blue-2c;
					font:12px/15px ($catamaran);
                                        font-weight: $catamaran-regular;
					color:$blue-2c;
					padding:3px;
					display:block;
				}
				&.active a {
					background-color:$blue-2c;
					color:$btn-primary-color;
				}
			}
		}
		.navbar-nav {
			width:100%;
			height:100%;
			overflow:auto;
			overflow-x:hidden;
			li {
				float:none;
				opacity:0;
				a {
					font:32px/24px ($catamaran);
                                        font-weight: $catamaran-extrabold;
					letter-spacing:-1.5px;
					display:inline-block;
					color:$blue;
					background:none !important;
					margin:8px 0;
					padding:4px 0;
					position:relative;
					&:hover:after {
						width:100%;
					}
					&:after {
						@include menu-hover;
					}
				}
				&.active a:after {
					@include menu-hover;
					width:100%;
				}
				&.bottom-menu {
					padding-bottom:30px;
					li {
						padding:0 20px;
						&:nth-of-type(1) {
							animation-delay:1.3s;
						}
						&:nth-of-type(2) {
							animation-delay:1.4s;
						}
						&:nth-of-type(3) {
							animation-delay:1.5s;
						}
					}
					a {
						font:18px ($catamaran);
                                                font-weight: $catamaran-light;
						letter-spacing:-0.5px;
					}
				}	
			}
		}
	}
}